import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faFillDrip,
  faHourglassHalf,
  faScaleUnbalanced,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import StatisticsCard from "../uielements/StatisticsCard";
import Api from "../../helpers/apiHandler";
import countsActions from "../../redux/counts/actions";
import { newPermissionValue } from "../../helpers/constants";

const { updateCount } = countsActions;
const api = new Api();

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  @media only screen and (max-width: 1080px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media only screen and (max-width: 960px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media only screen and (max-width: 424px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const ProductionCounts = ({
  onUpdateCount,
  counts,
  companyId,
  sideBarItems,
  ...props
}) => {
  const [count, setCount] = useState({});
  const [isRecipe, setIsRecipe] = useState(false);
  const [isIngredients, setIsIngredients] = useState(false);
  const [isProduction, setIsProduction] = useState(false);

  useEffect(() => {
    if (sideBarItems && sideBarItems.length > 0) {
      sideBarItems.forEach((item) => {
        if (item.moduleName === "recipe") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsRecipe(true);
          } else {
            setIsRecipe(false);
          }
        }
        if (item.moduleName === "ingredients") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsIngredients(true);
          } else {
            setIsIngredients(false);
          }
        }
        if (item.moduleName === "Production Run") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsProduction(true);
          } else {
            setIsProduction(false);
          }
        }
      });
    }
  }, [sideBarItems]);

  useEffect(() => {
    if (companyId) {
      getCount();
    }
  }, [companyId]);

  useEffect(() => {
    if (counts) {
      setCount(counts);
    }
  }, [counts]);

  const getCount = () => {
    const payload = {
      companyId: companyId,
      menuType: "PRODUCTION",
    };
    api
      .post("common/count", {
        isMultipart: false,
        data: payload,
      })
      .then((response) => {
        if (response.status === 1) {
          onUpdateCount({
            production: {
              recipes: response.data.recipe,
              ingredients: response.data.ingredient,
              currentProjects: response.data.currentProject,
              upcomingProjects: response.data.upComingProject,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  return (
    <GridContainer className="width-100">
      {isRecipe && (
        <StatisticsCard
          title={"Recipes"}
          count={count.recipes || 0}
          icon={
            <FontAwesomeIcon
              icon={faUtensils}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#9D6AB0"}
          onClick={() => props.history.push("/recipe")}
        />
      )}
      {isIngredients && (
        <StatisticsCard
          title={"Ingredients"}
          count={count.ingredients || 0}
          icon={
            <FontAwesomeIcon
              icon={faFillDrip}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#8BBC27"}
          onClick={() => props.history.push("/ingredients")}
        />
      )}
      {isProduction && (
        <StatisticsCard
          title={"Current Production Runs"}
          count={count.currentProjects || 0}
          icon={
            <FontAwesomeIcon
              icon={faHourglassHalf}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#20CCE2"}
          onClick={() => props.history.push("/production")}
        />
      )}
      {isProduction && (
        <StatisticsCard
          title={"Upcoming Production Runs"}
          count={count.upcomingProjects || 0}
          icon={
            <FontAwesomeIcon
              icon={faCalendarAlt}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#EE5A92"}
          onClick={() => props.history.push("/production")}
        />
      )}
      {/* <StatisticsCard
        title={
          <span>
            Weights and
            <br /> Measures
          </span>
        }
        icon={
          <FontAwesomeIcon
            icon={faScaleUnbalanced}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#348EE2"}
        showOnlyTitle
      /> */}
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.Auth.company.id,
  counts: state.Counts.production,
  sideBarItems: state.Auth.sideBarItems,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateCount: (data) => dispatch(updateCount(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductionCounts)
);
