import styled from "styled-components";

export const StylesWrapper = styled.div`
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${(props) => (props.color ? props.color : "")} !important;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")} !important;
  height: 94px !important;
  .iconBox {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardTitle {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
  }
  .count {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
  }
  .cardTitle2 {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    line-height: 20px;
  }
  .customSelect {
    .ant-select-selection {
      background-color: rgba(255, 255, 255, 0.2) !important;
      border: 1px solid rgba(255, 255, 255, 0.2) !important;
      border-radius: 99px !important;
      height: 24px !important;
      display: flex;
      align-items: center;
      .ant-select-selection__rendered {
        line-height: 18px !important;
        .ant-select-selection-selected-value {
          color: #fff !important;
          font-size: 14px !important;
        }
      }
      .ant-select-arrow {
        transform: translateY(-8px) !important;
        margin-top: 0 !important;
      }
    }
  }
`;
