import './stylus/global.css';
import 'antd/dist/antd.css';

import { history, store } from './redux/store';

import Boot from './redux/boot';
import DashAppHolder from './dashAppStyle';
import { Provider } from 'react-redux';
import PublicRoutes from './router';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { themeConfig } from './settings';
import themes from './settings/themes';
import { StripeProvider } from 'react-stripe-elements';

const DashApp = () => (
	<StripeProvider apiKey="pk_test_bC1yTUnSRw9AUS2yOnoBRRyw00AZX3DDBl">
		<ThemeProvider theme={themes[themeConfig.theme]}>
			<DashAppHolder>
				<Provider store={store}>
					<PublicRoutes history={history} />
				</Provider>
			</DashAppHolder>
		</ThemeProvider>
	</StripeProvider>
);
Boot().then(() => DashApp()).catch((error) => console.error(error));

export default DashApp;
