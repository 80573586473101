import React, { useState } from 'react'
import { connect } from 'react-redux'
import { injectStripe, CardElement } from 'react-stripe-elements'
import { StyledPayment } from './payment.style'
import Button from '../../components/uielements/button'
import { message } from 'antd'
import { siteConfig } from '../../settings'
import authActions from '../../redux/auth/actions'
import Api from '../../helpers/apiHandler'

const { pendingPayment } = authActions
const styleStripe = { base: { fontSize: '18px' } }
const api = new Api()
const StripeForm = props => {
  const [cardErrors, setCardErrors] = useState({ card: '' })
  const [loading, setLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    await setLoading(true)
    let { token, error } = await props.stripe.createToken()
    if (error) {
      message.error(error.message)
    } else {
      let params = {
        userId: props.id,
        token: token.id
      }
      if (props.callApi) {
        api
          .post('paymentInfo/chargeCreditCard', {
            isMultipart: false,
            data: params
          })
          .then(response => {
            if (response.status === 1) {
              message.success(response.message)
              props.pendingPayment({ showPage: false, userId: 0, hasCard: false })
            } else {
              message.error(response.message)
            }
          })
      } else {
        api
          .post('paymentInfo/saveCard', {
            isMultipart: false,
            data: params
          })
          .then(response => {
            if (response.status === 1) {
              message.success(response.message)
              props.pendingPayment({ showPage: false, hasCard: false })
            } else {
              message.error(response.message)
            }
          })
      }
    }
    await setLoading(false)
  }

  const elementChange = e => {
    let oldError = { ...cardErrors }
    oldError[e.elementType] = e.error && e.error.message
    setCardErrors(oldError)
  }

  return (
    <StyledPayment>
      <div className='content-container'>
        <form className='ma-50px' onSubmit={handleSubmit}>
          <h4>Please enter payment info to continue using {siteConfig.siteName}.</h4>
          <h2 className='mtb-10px'>Enter Card Details:</h2>
          <CardElement style={styleStripe} onChange={elementChange} />
          <div className='ma-bottom-20px error-msg'>{cardErrors.card}</div>
          <div className='width-100 c-center'>
            <Button
              htmlType='submit'
              value='Save'
              type='primary'
              className='btn btn-block large plr-20px ptb-5px'
              loading={loading}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </StyledPayment>
  )
}

const mapStateToProps = state => ({
  id: state.Auth.id
})

export default connect(mapStateToProps, { pendingPayment })(injectStripe(StripeForm))
