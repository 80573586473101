import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReceipt,
  faTruckFast,
  faTruckRampBox,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import StatisticsCard from "../uielements/StatisticsCard";
import Api from "../../helpers/apiHandler";
import countsActions from "../../redux/counts/actions";
import { newPermissionValue } from "../../helpers/constants";

const { updateCount } = countsActions;
const api = new Api();

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 20px;
  @media only screen and (max-width: 1500px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media only screen and (max-width: 540px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const dropDownOption = [
  { key: "today", value: "Today" },
  { key: "week", value: "This Week" },
  { key: "month", value: "This Month" },
  { key: "quarter", value: "This Quarter" },
  { key: "year", value: "This Year" },
];

const CustomersCount = ({
  onUpdateCount,
  counts,
  companyId,
  sideBarItems,
  ...props
}) => {
  const [count, setCount] = useState({});
  const [customerCount, setCustomerCount] = useState(0);
  const [isCustomers, setIsCustomers] = useState(false);
  const [isCustomerOrders, setIsCustomerOrders] = useState(false);

  useEffect(() => {
    if (sideBarItems && sideBarItems.length > 0) {
      sideBarItems.forEach((item) => {
        if (item.moduleName === "customers") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsCustomers(true);
          } else {
            setIsCustomers(false);
          }
        }
        if (item.moduleName === "customer_orders") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsCustomerOrders(true);
          } else {
            setIsCustomerOrders(false);
          }
        }
      });
    }
  }, [sideBarItems]);

  useEffect(() => {
    if (companyId) {
      getCount();
    }
  }, [companyId]);

  useEffect(() => {
    if (counts) {
      setCount(counts);
    }
  }, [counts]);

  const getCount = () => {
    const payload = {
      companyId: companyId,
      menuType: "CUSTOMERS",
    };
    api
      .post("common/count", {
        isMultipart: false,
        data: payload,
      })
      .then((response) => {
        if (response.status === 1) {
          onUpdateCount({
            customers: {
              pendingOrders: response.data.orderCounts[0].pending,
              overdueOrders: response.data.orderCounts[0].overdue,
              customersOverview: response.data.customerOverView[0],
            },
          });
          if (
            response.data.customerOverView[0] &&
            response.data.customerOverView[0].today
          ) {
            setCustomerCount(response.data.customerOverView[0].today);
          }
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleChange = (e) => {
    setCustomerCount(count.customersOverview[e]);
  };

  return (
    <GridContainer className="width-100">
      {isCustomers && (
        <StatisticsCard
          title={"Customers"}
          count={
            count.customersOverview && count.customersOverview.total
              ? count.customersOverview.total
              : 0
          }
          icon={
            <FontAwesomeIcon
              icon={faUserGroup}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#9D6AB0"}
          onClick={() => props.history.push("/customers")}
        />
      )}
      {isCustomerOrders && (
        <StatisticsCard
          title={"Pending Orders"}
          count={count.pendingOrders ? count.pendingOrders : 0}
          icon={
            <FontAwesomeIcon
              icon={faReceipt}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#8BBC27"}
        />
      )}
      {isCustomerOrders && (
        <StatisticsCard
          title={"Overdue Orders"}
          count={count.overdueOrders ? count.overdueOrders : 0}
          icon={
            <FontAwesomeIcon
              icon={faTruckRampBox}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#20CCE2"}
        />
      )}
      {isCustomerOrders && (
        <StatisticsCard
          title={
            <span>
              Customer
              <br /> Orders
            </span>
          }
          icon={
            <FontAwesomeIcon
              icon={faTruckFast}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#EE5A92"}
          showOnlyTitle
          onClick={() => props.history.push("/customer_orders")}
        />
      )}
      {isCustomers && (
        <StatisticsCard
          title={"Customers Overview"}
          count={customerCount}
          icon={
            <FontAwesomeIcon
              icon={faUserGroup}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#348EE2"}
          showDropdown
          option={dropDownOption}
          dropDownChange={handleChange}
        />
      )}
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.Auth.company.id,
  counts: state.Counts.customers,
  sideBarItems: state.Auth.sideBarItems,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateCount: (data) => dispatch(updateCount(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomersCount)
);
