const actions = {
  SET_INGREDIENT: "SET_INGREDIENT",
  SEARCH_USDA: "SEARCH_USDA",
  SET_ORDER_ING_ID: "SET_ORDER_ING_ID",
  SET_ORDERS: "SET_ORDERS",
  SET_ITEM: "SET_ITEM",
  SET_RECIPE: "SET_RECIPE",
  SET_CUSTOMER: "SET_CUSTOMER",
  SET_INSPECTION: "SET_INSPECTION",
  SET_STEP_FORM: 'SET_STEP_FORM',
  SET_DIVIDER_STEP_FORM: 'SET_DIVIDER_STEP_FORM',

  setIngredientsDetails: (data) => ({ type: actions.SET_INGREDIENT, data }),
  setUsdaSearch: (data) => ({ type: actions.SEARCH_USDA, data }),
  setOrderIng: (id) => ({ type: actions.SET_ORDER_ING_ID, data: id }),
  setOrderToProcess: (order) => ({ type: actions.SET_ORDERS, data: order }),
  setItem: (data) => ({ type: actions.SET_ITEM, data: data }),
  setRecipe: (data) => ({ type: actions.SET_RECIPE, data: data }),
  setCustomer: (data) => ({ type: actions.SET_CUSTOMER, data: data }),
  setInspection: (data) => ({ type: actions.SET_INSPECTION, data: data }),
  setStepForm: (data) => ({ type: actions.SET_STEP_FORM, data: data }),
  setDividerStepForm: (data) => ({ type: actions.SET_DIVIDER_STEP_FORM, data: data }),
};
export default actions;
