const actions = {
	CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGOUT: 'LOGOUT',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_ERROR: 'LOGIN_ERROR',
	UPDATE_PROFILE: 'UPDATE_PROFILE',
	PENDING_PAYMENT: 'PENDING_PAYMENT',

	checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
	login: () => ({
		type: actions.LOGIN_REQUEST
	}),
	logout: () => ({
		type: actions.LOGOUT
	}),
	updateProfile: (data) => ({
		type: actions.UPDATE_PROFILE,
		data
	}),
	pendingPayment: (data) => ({
		type: actions.PENDING_PAYMENT,
		data
	})
};
export default actions;
