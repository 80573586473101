import { all, fork, put, takeEvery } from "redux-saga/effects";
import { clearToken, getProfileDetail, getToken } from "../../helpers/utility";

import Api from "../../helpers/apiHandler";
import actions from "./actions";
import { push } from "react-router-redux";

const fakeApiCall = true; // auth0 or express JWT
const api = new Api();

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* () {
    if (fakeApiCall) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: "secret token",
        profile: "Profile",
      });
    } else {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem("id_token", payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken();
    yield put(push("/"));
  });
}
export function* pendingPayment() {
  yield takeEvery(actions.PENDING_PAYMENT, function* (payload) {
    if (payload.data.showPage) {
      if (payload.data.fromEmployee) {
        yield put(push("/paymentinfo?fromEmployee=true"));
      } else {
        yield put(push("/paymentinfo"));
      }
    } else {
      yield put(push("/signin"));
    }
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get("idToken");
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: "Profile",
      });
    }
    getProfileDetail();
    api.getAuthenticationInfo();
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(pendingPayment),
  ]);
}
