import React, { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { message } from 'antd'
import { Elements } from 'react-stripe-elements'
import StripeForm from './StripeForm'
import authActions from '../../redux/auth/actions'
import { siteConfig } from '../../settings'
import { StyledPayment } from './payment.style'
import qs from 'query-string'

const { pendingPayment } = authActions
const PaymentScreen = props => {
  const [showStripe, setShowStripe] = useState(false)
  const [callApi, setCallApi] = useState(true)

  useEffect(() => {
    if (!props.id) {
      message.error('Please try sign in again !!!')
      props.pendingPayment({ showPage: false, userId: 0, hasCard: false })
      return
    } else {
      let qString = qs.parse(props.location.search, { parseBooleans: true })
      setCallApi(!qString.fromEmployee)
      setShowStripe(!props.hasCard)
    }
  }, [])

  return (
    <Elements>
      {showStripe ? (
        <StripeForm callApi={callApi} />
      ) : (
        <StyledPayment>
          <div className='content-container pa-50px'>
            <h3>Please enter payment info to continue using this feature on {siteConfig.siteName}.</h3>
            <h3 className='mtb-10px'>
              Error occured while processing payment with existing details.<br />
              Payment process will be be retried in 24 hours.<br />
              <span className='blue-text cursor-pointer' onClick={() => setShowStripe(true)}>
                Click here
              </span>{' '}
              to add new card details.
            </h3>
          </div>
        </StyledPayment>
      )}
    </Elements>
  )
}

const mapStateToProps = state => ({
  id: state.Auth.id,
  hasCard: state.Auth.hasCard
})

export default connect(mapStateToProps, { pendingPayment })(PaymentScreen)
