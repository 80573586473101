const awsPaths = {
  originalImg: "local/user/profileimage",
  thumbImg: "local/user/profileimagethumb",
  companyLogo: "local/company/logo",
  productionUnit: "local/production_unit",
  certificateProductionUnit: "local/certificate/productionUnit",
  certificateProductionUnitThumb: "local/certificate/productionUnit/thumb",
  certificateIngredientsThumb: "local/certificate/ingredients/thumb",
  certificateIngredients: "local/certificate/ingredients",
  certificateManufacturerThumb: "local/certificate/manufacturer/thumb",
  certificateManufacturer: "local/certificate/manufacturer",
  certificateEmpThumb: "local/certificate/emp/thumb",
  certificateEmp: "local/certificate/emp",
  documentManufacturer: "local/document/manufacturer",
  customerDocument: "local/document/customer",
  documentEmp: "local/document/emp",
  certificateSupplier: "local/certificate/supplier",
  ingredients: "local/ingredients",
  inventoryImages: "local/inventory/images",
  inventoryDocs: "local/inventory/docs",
  bolDocs: "local/bol/docs",
  lotDocs: "local/lot/docs",
  recipes: "local/recipes",
  recipesDocs: "local/recipes/docs",
  electronicSignature: "local/electronicSignature",
  webCamImage: "local/webCamImage",
  inspection: "local/inspections",
  taskImageThumb: "local/task/imageThumb",
  taskImage: "local/task/image",
  inspectionImageThumb: "local/inspection/imageThumb",
  inspectionImage: "local/inspection/image",
  task: "local/task",
  recipeStep: "local/recipe/step",
  productionNoteImageThumb: "/local/productionRun/imageThumb",
  productionNote: "local/productionRun/image",
};
export const awsBucketName = "foodbusinesstracker";
export const awsRegion = "us-west-2";
export const awsAccessKeyId = "AKIATSVGONKITIOIHIQZ";
export const awsSecretAccessKey = "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";

// export const basePath = "https://da0ir44tr50ha.cloudfront.net";
export const basePath =
  "https://foodbusinesstracker.s3.us-west-2.amazonaws.com";

export default awsPaths;
