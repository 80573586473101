import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import HomeCounts from "./HomeCounts";
import TaskMessagingCounts from "./TaskMessagingCounts";
import RegulatoryComplianceCounts from "./RegulatoryComplianceCounts";
import ProductionCounts from "./ProductionCounts";
import CustomersCount from "./CustomersCount";
import SuppliersCount from "./SuppliersCount";
import HrCounts from "./HrCounts";
import SettingsCount from "./SettingsCount";
import { getMenuItemData } from "../../helpers/utility";

const Counts = ({ ...props }) => {
  const companyId = props.auth.company.id;
  const getCountsComponent = () => {
    let pathname = props.location.pathname;
    if (
      getMenuItemData("home").modules.some((item) =>
        pathname.substr(1).startsWith(item)
      )
    ) {
      return <HomeCounts />;
    } else if (
      getMenuItemData("task and messaging").modules.some((item) =>
        pathname.substr(1).startsWith(item)
      )
    ) {
      return <TaskMessagingCounts />;
    } else if (
      getMenuItemData("regulatory and compliance").modules.some((item) =>
        pathname.substr(1).startsWith(item)
      )
    ) {
      return <RegulatoryComplianceCounts />;
    } else if (
      getMenuItemData("production").modules.some((item) =>
        pathname.substr(1).startsWith(item)
      )
    ) {
      return <ProductionCounts />;
    } else if (
      getMenuItemData("customers").modules.some((item) =>
        pathname.substr(1).startsWith(item)
      )
    ) {
      return <CustomersCount />;
    } else if (
      getMenuItemData("supplier").modules.some((item) =>
        pathname.substr(1).startsWith(item)
      )
    ) {
      return <SuppliersCount />;
    } else if (
      getMenuItemData("hr").modules.some((item) =>
        pathname.substr(1).startsWith(item)
      )
    ) {
      return <HrCounts />;
    } else if (
      getMenuItemData("settings").modules.some((item) =>
        pathname.substr(1).startsWith(item)
      )
    ) {
      return <SettingsCount />;
    }
    return;
  };

  let componentToRender = getCountsComponent();
  if (componentToRender) {
    return <div className="plr-20px pa-top-20px">{componentToRender}</div>;
  }
  return <React.Fragment />;
};

const mapStateToProps = (state) => ({
  auth: state.Auth,
});

export default withRouter(connect(mapStateToProps)(Counts));
