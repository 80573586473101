import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faComments,
  faPallet,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Api from "../../helpers/apiHandler";
import StatisticsCard from "../uielements/StatisticsCard";
import countsActions from "../../redux/counts/actions";

const { updateCount } = countsActions;
const api = new Api();

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 24px;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const HomeCounts = ({ onUpdateCount, counts, companyId, ...props }) => {
  const [count, setCount] = useState({});

  useEffect(() => {
    if (companyId) {
      getCount();
    }
  }, [companyId]);

  useEffect(() => {
    if (counts) {
      setCount(counts);
    }
  }, [counts]);

  const getCount = () => {
    const payload = {
      companyId: companyId,
      menuType: "HOME",
    };
    api
      .post("common/count", {
        isMultipart: false,
        data: payload,
      })
      .then((response) => {
        if (response.status === 1) {
          onUpdateCount({
            home: {
              activeTasks: response.data.activeTask,
              unreadMessages: response.data.unReadMsg,
              unreadNotifications: response.data.unReadNotification,
              activeProductionRuns: response.data.activeProductionRun,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  return (
    <GridContainer className="width-100">
      <StatisticsCard
        title={"Active Tasks"}
        count={count.activeTasks || 0}
        icon={
          <FontAwesomeIcon
            icon={faTasks}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#9D6AB0"}
        onClick={() => props.history.push("/task")}
      />
      <StatisticsCard
        title={"Unread Messages"}
        count={count.unreadMessages || 0}
        icon={
          <FontAwesomeIcon
            icon={faComments}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#16B9CE"}
      />
      <StatisticsCard
        title={"Unread Notifications"}
        count={count.unreadNotifications || 0}
        icon={
          <FontAwesomeIcon
            icon={faBell}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#EE5A92"}
      />
      <StatisticsCard
        title={"Active Production Runs"}
        count={count.activeProductionRuns || 0}
        icon={
          <FontAwesomeIcon
            icon={faPallet}
            style={{
              height: "16px",
              width: "16px",
              color: "#fff",
            }}
          />
        }
        color={"#8BBC27"}
        onClick={() => props.history.push("/production")}
      />
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.Auth.company.id,
  counts: state.Counts.home,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateCount: (data) => dispatch(updateCount(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeCounts)
);
