import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Image from '../../image/rob.png';
import FourZeroFourStyleWrapper from './404.style';

class Page404 extends Component {
	render() {
		return (
			<FourZeroFourStyleWrapper className="iso404Page">
				<div className="iso404Content">
					<h1>404</h1>
					<h3>Looks like you got lost</h3>
					<p>
						The page youre looking for doesnt exist or has been
						moved.
					</p>
					<button type="button">
						<Link
							to={
								this.props.userType === 'superadmin' ? (
									'/companies'
								) : (
									'/dashboard'
								)
							}>
							BACK HOME
						</Link>
					</button>
				</div>

				<div className="iso404Artwork">
					<img alt="#" src={Image} />
				</div>
			</FourZeroFourStyleWrapper>
		);
	}
}

const mapStateToProps = (state) => ({
	userType: state.Auth.type
});

export default connect(mapStateToProps)(Page404);
