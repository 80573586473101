import "./global.css";

import { Form, Layout } from "antd";
import React, { Component } from "react";

import Api from "./../../helpers/apiHandler";
import AppHolder from "./commonStyle";
import AppRouter from "./AppRouter";
import Loader from "../../components/utility/loader";
import Sidebar from "../Sidebar/Sidebar";
import { ThemeProvider } from "styled-components";
import Topbar from "../Topbar/Topbar";
import WindowResizeListener from "react-window-size-listener";
import appActions from "../../redux/app/actions";
import authAction from "../../redux/auth/actions";
import { connect } from "react-redux";
import { siteConfig } from "../../settings";
import { themeConfig } from "../../settings";
import themes from "../../settings/themes";
import Counts from "../../components/Counts";

const api = new Api();
let timeoutVar;
const { Content, Footer } = Layout;
const { logout, updateProfile } = authAction;
const { toggleAll, changeCurrent } = appActions;

export class App extends Component {
  state = {
    isLoading: false,
    isAddModalVisible: false,
    selectedCompany: null,
    selectedProductionUnit: null,
    collapsed: true,
    collapsedWidth: 0,
    _767: false,
    _1199: false,
  };

  constructor(props) {
    super(props);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  componentDidMount() {
    const { id, type } = this.props.auth;
    if (id && type !== "superadmin") {
      this.getData(this.props);
    }
    if (this.state._1199 || this.state._767) {
      this.setState({ collapsed: true });
    } else {
      this.setState({ collapsed: true });
    }
    this.handleWindowSizeChange();
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props && this.props.location && this.props.location.pathname) {
      const urlArray = this.props.location.pathname.split("/");
      if (urlArray.length > 0 && urlArray[1])
        this.props.changeCurrent([urlArray[1]]);
    }
    if (
      prevState._767 !== this.state._767 ||
      prevState._1199 !== this.state._1199
    ) {
      if (this.state._767) {
        this.setState({ collapsed: true, collapsedWidth: 0 });
      } else if (this.state._1199) {
        this.setState({ collapsed: true, collapsedWidth: 0 });
      } else {
        this.setState({ collapsed: true, collapsedWidth: 0 });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.auth.id != nextProps.auth.id) {
      const { id, type } = nextProps.auth;
      if (id && type !== "superadmin") {
        this.getData(nextProps);
      }
    }
  }

  getData(callProps) {
    this.setState({ isLoading: true });

    // let admin_data = JSON.parse(localStorage.getItem('user'));
    let url = "company/list/" + callProps.auth.id;
    api
      .get(url)
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.status === 1) {
          let companies = response.data.companyList;
          let result = companies.filter((record) => {
            return record.productionUnitCount > 0;
          });

          this.setState({ selectedCompany: companies[0] });
          // localStorage.setItem('company', JSON.stringify(companies[0]));
          callProps.updateProfile({ company: companies[0] });

          if (result.length > 0) {
            this.getProductionUnitList();
          } else {
            this.setState({
              isLoading: true,
              isAddModalVisible: true,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        return error;
      });
  }

  getProductionUnitList() {
    let url =
      "companyProductionUnit/list/" + this.state.selectedCompany.id.toString();

    this.setState({ isLoading: true });

    this.state.selectedCompany.id.toString() &&
      api
        .get(url)
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.status === 1) {
            let productionUnits = response.data.companyProductionUnitList;

            this.setState({
              selectedProductionUnit: productionUnits[0],
            });
          } else {
            console.log("!200");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          return error;
        });
  }

  toggleCollapsed() {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  }

  handleWindowSizeChange() {
    const _767 = window.matchMedia("(max-width: 767px)").matches;
    const _1199 = window.matchMedia(
      "(min-width: 767px) and (max-width: 1200px)"
    ).matches;
    this.setState({ _767, _1199 });
  }

  render() {
    const { url } = this.props.match;
    const { height } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: "100vh" }}>
            <WindowResizeListener
              onResize={(windowSize) => {
                if (timeoutVar) {
                  clearTimeout(timeoutVar);
                }
                timeoutVar = setTimeout(() => {
                  this.props.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  );
                }, 1000);
              }}
            />
            <Layout
              style={{
                flexDirection: "row",
                overflowX: "hidden",
              }}
            >
              <Sidebar
                url={url}
                collapsed={this.state.collapsed}
                collapsedWidth={this.state.collapsedWidth}
                siderStyle={
                  this.state._1199
                    ? { position: "fixed", left: 0, top: 0, bottom: 0 }
                    : this.state._767
                    ? { position: "fixed", left: 0, top: 0, bottom: 0 }
                    : { position: "fixed", left: 0, top: 0, bottom: 0 }
                }
                // showCollapseBtn={
                //   (this.state._1199 || this.state._767) && !this.state.collapsed
                // }
                showCollapseBtn={!this.state.collapsed}
                handleSidebarCollapsedBtnClick={this.toggleCollapsed}
              />
              <Layout
                className="isoContentMainLayout"
                style={
                  this.state._767
                    ? { height: "100vh" }
                    : this.state._1199
                    ? {
                        height: "100vh",
                        // marginLeft: "80px",
                      }
                    : { height: "100vh" }
                }
              >
                <Topbar
                  url={url}
                  companyName={this.props.auth.company.name}
                  toggleCollapsed={this.toggleCollapsed}
                  isSmallScreen={this.state._1199 || this.state._767}
                />
                <div className="flex-1 display-flex flex-column">
                  <Counts />
                  <Content
                    className="isomorphicContent"
                    style={{
                      padding: 0,
                      flexShrink: "0",
                      background: "#f1f3f6",
                      position: "relative",
                    }}
                  >
                    <AppRouter url={url} />
                  </Content>
                  <Footer
                    style={{
                      background: "#ffffff",
                      textAlign: "center",
                      borderTop: "1px solid #ededed",
                      padding: "10px 16px",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {siteConfig.footerText}
                  </Footer>
                </div>
              </Layout>
              {(this.state._1199 || this.state._767) &&
              this.state.collapsed == false ? (
                <div className="layoutOverlay"></div>
              ) : (
                ""
              )}
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

// export default connect(
//   state => ({
//     auth: state.Auth,
//     height: state.App.toJS().height
//   }),
//   { logout, toggleAll }
// )(App);

const mapStateToProps = (state) => ({
  auth: state.Auth,
  height: state.App.toJS().height,
});

export default Form.create()(
  connect(mapStateToProps, { logout, toggleAll, updateProfile, changeCurrent })(
    App
  )
);
