import awsPath, {
  awsAccessKeyId,
  awsBucketName,
  awsRegion,
  awsSecretAccessKey,
} from "./awsPaths";

// import AWS from 'aws-sdk'
import Api from "../helpers/apiHandler";
import { Map } from "immutable";
import SS3 from "aws-sdk/clients/s3";
import authActions from "../redux/auth/actions";
import menuList, { superadminDashboard } from "../containers/Sidebar/options";
import { store } from "../redux/store";
import options from "../containers/Sidebar/options";
import { loginUserType, moduleIds, newPermissionValue } from "./constants";

const s3 = new SS3({
  accessKeyId: awsAccessKeyId,
  secretAccessKey: awsSecretAccessKey,
});

const api = new Api();

export const imageExtensions = ["jpeg", "jpg", "png"];

export function clearToken() {
  localStorage.removeItem("id_token");
  const storeObject = {
    accountStatus: "",
    email: "",
    firstName: "",
    id: "",
    lastName: "",
    profileImage: "",
    role: "",
    type: "",
    isLoggedIn: false,
    accessToken: getXAPIKey(),
  };
  store.dispatch(authActions.updateProfile(storeObject));
}

export function getToken() {
  try {
    const idToken = localStorage.getItem("id_token");
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function getProfileDetail() {
  const isLogin = localStorage.getItem("isLoggedIn");
  if (isLogin === "true" || isLogin === true) {
    api
      .post("user/onRefreshGetView")
      .then((e) => {
        if (e.status == "1") {
          new Promise(() => {
            // store.dispatch(authActions.checkAuthorization());

            let allUserPermissionList = [];
            let existsPermissionList = [];
            if (e.data.activatePlanModuleList.length > 0) {
              if (
                e.data.type === loginUserType.admin &&
                e.data.allModuleList.length > 0
              ) {
                existsPermissionList = e.data.allModuleList.filter((x) =>
                  e.data.activatePlanModuleList.includes(x.name)
                );
                allUserPermissionList = existsPermissionList.map(
                  (permissionObj) => {
                    const { id, name, status, description } = permissionObj;

                    const userPermission = {
                      moduleId: id,
                      moduleName: name,
                      permissionId: 1,
                      moduleStatus: status,
                      moduleDescription: description,
                      subModules: [],
                    };
                    return userPermission;
                  }
                );
              } else if (e.data.type === loginUserType.employee) {
                existsPermissionList =
                  e.data.empPermissionList.length > 0
                    ? e.data.empPermissionList
                    : e.data.rolePermissionList;

                existsPermissionList = existsPermissionList.filter((x) =>
                  e.data.activatePlanModuleList.includes(x.moduleName)
                );

                allUserPermissionList = existsPermissionList.map(
                  (permissionObj) => {
                    const {
                      moduleId,
                      moduleName,
                      permissionId,
                      status,
                      description,
                      subModules,
                    } = permissionObj;

                    const userPermission = {
                      moduleId,
                      moduleName,
                      permissionId,
                      moduleStatus: status,
                      moduleDescription: description,
                      subModules: subModules.map((subModule) => ({
                        subModuleId: subModule.subModuleId,
                        tabName: subModule.subModuleName,
                        subModuleFieldVisibility:
                          subModule.subModuleFieldVisibility,
                        isTabVisible: subModule.isTabVisible,
                        description: subModule.description,
                      })),
                    };

                    return userPermission;
                  }
                );
              }
            }

            // sort Array by module Id
            // allUserPermissionList.sort((a, b) => {
            //   return a.moduleId - b.moduleId;
            // });

            const storeObject = {
              accountStatus: e.data.accountStatus,
              email: e.data.email,
              firstName: e.data.firstName,
              id: e.data.id,
              lastName: e.data.lastName,
              profileImage: e.data.profileImage,
              role: e.data.role,
              type: e.data.type,
              activatedPlanId: e.data.activatedPlanId,
              anyPlanActivated: e.data.anyPlanActivated,
              activatePlanModuleList: e.data.activatePlanModuleList,
              sideBarItems:
                e.data.type === "superadmin"
                  ? superadminDashboard
                  : allUserPermissionList,
            };
            store.dispatch(authActions.updateProfile(storeObject));

            let url = "company/list/" + e.data.id;
            api
              .get(url)
              .then((response) => {
                if (response.status === 1) {
                  store.dispatch(
                    authActions.updateProfile({
                      company: response.data.list[0],
                    })
                  );
                }
              })
              .catch((error) => {
                return error;
              });
          });
        }
      })
      .catch((e) => console.log(e));
  }

  const handleLogout = () => {
    let url = "user/logout";
    api
      .post(url)
      .then((response) => {
        if (response.status === 1) {
          this.props.logout();
        }
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getDeviceType() {
  return "web";
}

export function getXAPIKey() {
  return "eyJhbGciOiJIUzUxMiJ9";
}

export function setPermissionType(permissions) {
  let restrictedMenu = menuList.map((ele, i) => {
    if (permissions) {
      ele.permissionLevel = permissions[i];
      return ele;
    } else {
      ele.permissionLevel = 1;
      return ele;
    }
  });
  return restrictedMenu;
}

export function readFileContent(file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

//comment function to use async await
// export  function exportToS3 (fileToUpload, flag) {
//   var params = {
//     Body: fileToUpload,
//     Bucket: awsBucketName,
//     Key: `${awsPath[flag]}/${fileToUpload.name}`,
//     // Metadata: {
//     //   'Content-Disposition': 'attachment'
//     // },
//     ACL: "public-read",
//     ContentType: fileToUpload.type,
//   }

//   s3.putObject(params, (err, data) => {
//     if (err) {
//       console.log(err);
//     }
//   })
// }

export function exportToS3(fileToUpload, flag) {
  return new Promise((resolve, reject) => {
    const params = {
      Body: fileToUpload,
      Bucket: awsBucketName,
      Key: `${awsPath[flag]}/${fileToUpload.name}`,
      ACL: "public-read",
      ContentType: fileToUpload.type,
    };

    s3.putObject(params, (err, data) => {
      if (err) {
        console.error(err);
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export function deleteFromS3(fileToUpload, flag) {
  return new Promise((resolve, reject) => {
    s3.deleteObject(
      {
        Bucket: awsBucketName,
        Key: `${awsPath[flag]}/${fileToUpload}`,
      },
      (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      }
    );
  });
}

export function getLogo(str) {
  switch (str) {
    case "dashboard":
      return "fas fa-columns";
    case "company":
      return "far fa-building";
    case "employees":
      return "fas fa-user-tie";
    case "manufacturer":
      return "fas fa-industry";
    case "customers":
      return "fas fa-user-shield";
    case "supplier":
      return "fas fa-truck-moving";
    case "ingredients":
      return "fas fa-fill-drip";
    case "purchase-orders":
      return "fas fa-money-check";
    case "units":
      return "fas fa-balance-scale";
    case "customer_orders":
      return "fas fa-shipping-fast";
    case "inventory":
      return "fas fa-warehouse";
    case "labs":
      return "fas fa-flask";
    case "task_list":
      return "fas fa-door-open";
    case "recipe":
      return "fas fa-utensils";
    case "visitors":
      return "fas fa-street-view";
    case "inspection":
      return "fas fa-tasks";
    case "settings":
      return "fas fa-tools";
    case "reports":
      return "far fa-clipboard";
    case "companies":
      return "far fa-chart-bar";
    case "membership":
      return "fas fa-users-cog";
    case "task":
      return "fas fa-tasks";
    default:
      return "far fa-building";
  }
}

export function getMenuItemData(str, sideBarItems) {
  let name = str && str.toLowerCase();
  let menuItemData = null;
  if (name === "companies") {
    menuItemData = {
      icon: "fas fa-building",
      title: "Companies",
      route: "/companies",
      modules: ["companies"],
    };
  } else if (name === "membership settings") {
    menuItemData = {
      icon: "fas fa-users-cog",
      title: "Membership Settings",
      route: "/membership",
      modules: ["membership"],
    };
  } else if (name === "weights and measures") {
    menuItemData = {
      icon: "fas fa-balance-scale",
      title: "Weights & Measures",
      route: "/units",
      modules: ["weights", "measures"],
    };
  } else if (name === "roles") {
    menuItemData = {
      icon: "fas fa-id-badge",
      title: "Roles",
      route: "/roles",
      modules: ["roles"],
    };
  } else if (name === "home") {
    menuItemData = {
      icon: "fas fa-home",
      title: "Home",
      route: "/dashboard",
      modules: ["dashboard"],
    };
  } else if (name === "task and messaging") {
    let route = "";
    if (sideBarItems && sideBarItems.length > 0) {
      if (
        sideBarItems.find(
          (item) =>
            item.moduleName === "task" &&
            item.permissionId != newPermissionValue.noAccess
        )
      ) {
        route = "/task";
      }
    }
    menuItemData = {
      icon: "fas fa-tasks",
      title: "Tasks and Messaging",
      route,
      modules: ["task", "messages"],
    };
  } else if (name === "regulatory and compliance") {
    let route = "";
    if (sideBarItems && sideBarItems.length > 0) {
      if (
        sideBarItems.find(
          (item) =>
            item.moduleName === "inspection" &&
            item.permissionId != newPermissionValue.noAccess
        )
      ) {
        route = "/inspection";
      } else {
        route = "/visitors";
      }
    }
    menuItemData = {
      icon: "fa fa-gavel",
      title: "Regulatory & Compliance",
      route,
      modules: ["inspection", "visitors"],
    };
  } else if (name === "production") {
    let route = "";
    if (sideBarItems && sideBarItems.length > 0) {
      if (
        sideBarItems.find(
          (item) =>
            item.moduleName === "Production Run" &&
            item.permissionId != newPermissionValue.noAccess
        )
      ) {
        route = "/production";
      } else if (
        sideBarItems.find(
          (item) =>
            item.moduleName === "recipe" &&
            item.permissionId != newPermissionValue.noAccess
        )
      ) {
        route = "/recipe";
      } else {
        route = "/ingredients";
      }
    }
    menuItemData = {
      icon: "fa fa-industry",
      title: "Production",
      route,
      modules: ["production", "recipe", "ingredients"],
    };
  } else if (name === "inventory") {
    menuItemData = {
      icon: "fas fa-warehouse",
      title: "Inventory",
      route: "/inventory",
      modules: ["inventory"],
    };
  } else if (name === "customers") {
    let route = "";
    if (sideBarItems && sideBarItems.length > 0) {
      if (
        sideBarItems.find(
          (item) =>
            item.moduleName === "customers" &&
            item.permissionId != newPermissionValue.noAccess
        )
      ) {
        route = "/customers";
      } else {
        route = "/customer_orders";
      }
    }
    menuItemData = {
      icon: "fa fa-users",
      title: "Customers",
      route,
      modules: ["customers", "customer_orders"],
    };
  } else if (name === "supplier") {
    let route = "";
    if (sideBarItems && sideBarItems.length > 0) {
      if (
        sideBarItems.find(
          (item) =>
            item.moduleName === "supplier" &&
            item.permissionId != newPermissionValue.noAccess
        )
      ) {
        route = "/supplier";
      } else if (
        sideBarItems.find(
          (item) =>
            item.moduleName === "manufacturer" &&
            item.permissionId != newPermissionValue.noAccess
        )
      ) {
        route = "/manufacturer";
      } else {
        route = "/purchase-orders";
      }
    }
    menuItemData = {
      icon: "fas fa-shipping-fast",
      title: "Suppliers",
      route,
      modules: ["supplier", "manufacturer", "purchase-orders"],
    };
  } else if (name === "reports") {
    menuItemData = {
      icon: "far fa-clipboard",
      title: "Reports",
      route: "/reports",
      modules: ["reports"],
    };
  } else if (name === "hr") {
    let route = "";
    if (sideBarItems && sideBarItems.length > 0) {
      if (
        sideBarItems.find(
          (item) =>
            item.moduleName === "employees" &&
            item.permissionId != newPermissionValue.noAccess
        )
      ) {
        route = "/employees";
      } else if (
        sideBarItems.find(
          (item) =>
            item.moduleName === "teams" &&
            item.permissionId != newPermissionValue.noAccess
        )
      ) {
        route = "/teams";
      } else {
        route = "/roles";
      }
    }
    menuItemData = {
      icon: "fas fa-id-badge",
      title: "HR",
      route,
      modules: ["employees", "teams", "roles"],
    };
  } else if (name === "settings") {
    let route = "";
    if (sideBarItems && sideBarItems.length > 0) {
      if (
        sideBarItems.find(
          (item) =>
            item.moduleName === "My Settings" &&
            item.permissionId != newPermissionValue.noAccess
        )
      ) {
        route = "/my-settings";
      } else {
        route = "/global-settings";
      }
    }
    menuItemData = {
      icon: "fas fa-tools",
      title: "Settings",
      route,
      modules: ["my-settings", "global-settings", "settings"],
    };
  }
  return menuItemData;
}

export function differentiateUnits(mixedArray) {
  let standardArr = [];
  let customArr = [];
  mixedArray.map((ele) => {
    if (ele.companyId) customArr.push(ele);
    else standardArr.push(ele);
  });
  return { standardArr, customArr };
}

export async function createThumb(file) {
  return new Promise((resolve, reject) => {
    const obj = {};
    readFileContent(file)
      .then((content) => {
        const img = document.createElement("img");
        img.src = content;
        img.onload = () => {
          const MAX_WIDTH = 75;
          const MAX_HEIGHT = 75;
          let { naturalWidth: width, naturalHeight: height } = img;

          if (width > MAX_WIDTH || height > MAX_HEIGHT) {
            if (width / height > MAX_WIDTH / MAX_HEIGHT) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            } else {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob((blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const base64data = reader.result;
              const today = new Date();
              const imageName = `${
                today.getTime() + Math.floor(Math.random() * (20 - 0 + 1)) + 0
              }.png`;
              const compressed = {
                blob: blob,
                base64: base64data,
                name: today.getTime(),
                file: new File([blob], imageName),
              };
              obj.thumbImage = compressed;
              obj.renameImageName = imageName;
              const originalImg = {
                blob: blob,
                base64: base64data,
                name: today.getTime(),
                file: new File([blob], imageName),
              };
              obj.originalImage = originalImg;
              resolve(obj); // Resolve the promise with the populated object
            };
          });
        };
      })
      .catch((error) => {
        console.log(error);
        reject(error); // Reject the promise if there's an error
      });
  });
}

// Function to generate random string
export const generateRandomString = (length = 8) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const phoneNumberValidator = (rule, value, callback) => {
  try {
    const regex = /^\d{1,15}$/;
    if (value && !regex.test(value)) {
      throw new Error(
        "Phone number must be in digits and contain maximum 15 digits!"
      );
    } else {
      callback();
    }
  } catch (err) {
    callback(err);
  }
};

export const getFileExtension = (fileName) => {
  // Extract the part of the fileName after the last dot (.)
  if (fileName) {
    const dotIndex = fileName.lastIndexOf(".");
    if (dotIndex !== -1) {
      return fileName.slice(dotIndex + 1).toLowerCase();
    } else {
      return null; // No file extension found
    }
  }
  return null;
};

export const handleNumericInputChange = (
  value,
  callback,
  allowNegative = false
) => {
  if (allowNegative) {
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      callback();
    }
  } else {
    const reg = /^[0-9]*(\.[0-9]*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === "") {
      callback();
    }
  }
};
