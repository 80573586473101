import styled from "styled-components";
import { palette } from "styled-theme";

const AppHolder = styled.div`
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: ${palette("primary", 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${palette("secondary", 1)};

    &.isoContentMainLayout {
      /* overflow: auto;
      overflow-x: hidden;
      background-color: #ddddee;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      } */
    }
  }

  .isoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: #ffffff;
    border: 1px solid ${palette("border", 0)};
    height: 100%;
  }

  .isomorphicLayout {
    /* width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
    } */
  }

  .ant-layout-footer {
    font-size: 14px;
    font-weight: bold;
    padding: 15px 15px;
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
  }

  button {
    border-radius: 0;
  }

  .isoForm {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    /*background-color: #ccccdd;*/

    .isoInputWrapper {
      margin-bottom: 0px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      input {
        &::-webkit-input-placeholder {
          color: ${palette("grayscale", 0)};
        }

        &:-moz-placeholder {
          color: ${palette("grayscale", 0)};
        }

        &::-moz-placeholder {
          color: ${palette("grayscale", 0)};
        }
        &:-ms-input-placeholder {
          color: ${palette("grayscale", 0)};
        }
      }
    }

    .isoHelperText {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.2;
      color: ${palette("grayscale", 1)};
      padding-left: ${(props) =>
        props["data-rtl"] === "rtl" ? "inherit" : "13px"};
      padding-right: ${(props) =>
        props["data-rtl"] === "rtl" ? "13px" : "inherit"};
      margin: 15px 0;
      position: relative;
      display: flex;
      align-items: center;

      &:before {
        content: "*";
        color: ${palette("error", 0)};
        padding-right: 3px;
        font-size: 14px;
        line-height: 1;
        position: absolute;
        top: 2px;
        left: ${(props) => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
        right: ${(props) => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
      }
    }

    .isoHelperWrapper {
      margin-top: 5px;
      flex-direction: column;
    }

    .isoOtherLogin {
      padding-top: 40px;
      margin-top: 35px;
      border-top: 1px dashed ${palette("grayscale", 2)};

      > a {
        display: flex;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 10;
        }
      }

      button {
        width: 100%;
        height: 42px;
        border: 0;
        font-weight: 500;

        &.btnFacebook {
          background-color: #3b5998;

          &:hover {
            background-color: darken(#3b5998, 5%);
          }
        }

        &.btnGooglePlus {
          background-color: #dd4b39;
          margin-top: 15px;

          &:hover {
            background-color: darken(#dd4b39, 5%);
          }
        }

        &.btnAuthZero {
          background-color: #e14615;
          margin-top: 15px;

          &:hover {
            background-color: darken(#e14615, 5%);
          }
        }

        &.btnFirebase {
          background-color: ${palette("color", 5)};
          margin-top: 15px;

          &:hover {
            background-color: ${palette("color", 6)};
          }
        }
      }
    }

    button {
      font-weight: 500;
    }
  }
  .layoutOverlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(1,1,1,0.4);
    z-index: 999;
  }
`;

export default AppHolder;
