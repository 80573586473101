import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileSignature, faWalking } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import StatisticsCard from "../uielements/StatisticsCard";
import Api from "../../helpers/apiHandler";
import countsActions from "../../redux/counts/actions";
import { newPermissionValue } from "../../helpers/constants";

const { updateCount } = countsActions;
const api = new Api();

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  @media only screen and (max-width: 425px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const RegulatoryComplianceCounts = ({
  onUpdateCount,
  counts,
  companyId,
  sideBarItems,
  ...props
}) => {
  const [count, setCount] = useState({});
  const [isVisitor, setIsVisitor] = useState(false);
  const [isInspection, setIsInspection] = useState(false);

  useEffect(() => {
    if (sideBarItems && sideBarItems.length > 0) {
      sideBarItems.forEach((item) => {
        if (item.moduleName === "visitors") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsVisitor(true);
          } else {
            setIsVisitor(false);
          }
        }
        if (item.moduleName === "inspection") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsInspection(true);
          } else {
            setIsInspection(false);
          }
        }
      });
    }
  }, [sideBarItems]);

  useEffect(() => {
    if (companyId) {
      getCount();
    }
  }, [companyId]);

  useEffect(() => {
    if (counts) {
      setCount(counts);
    }
  }, [counts]);

  const getCount = () => {
    const payload = {
      companyId: companyId,
      menuType: "REGULATORY_AND_COMPLIANCE",
    };
    api
      .post("common/count", {
        isMultipart: false,
        data: payload,
      })
      .then((response) => {
        if (response.status === 1) {
          onUpdateCount({
            regulatoryAndCompliance: {
              numberOfVisitorsLog: response.data.visitorsLog,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  return (
    <GridContainer className="width-100">
      {isVisitor && (
        <StatisticsCard
          title={"Number of Visitors Log"}
          count={count.numberOfVisitorsLog || 0}
          icon={
            <FontAwesomeIcon
              icon={faWalking}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#9D6AB0"}
          onClick={() => props.history.push("/visitors")}
        />
      )}
      {isInspection && (
        <StatisticsCard
          title={"Inspection Directory"}
          icon={
            <FontAwesomeIcon
              icon={faFileSignature}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#16B9CE"}
          showOnlyTitle
          onClick={() => props.history.push("/inspection")}
        />
      )}
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.Auth.company.id,
  counts: state.Counts.regulatoryAndCompliance,
  sideBarItems: state.Auth.sideBarItems,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateCount: (data) => dispatch(updateCount(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegulatoryComplianceCounts)
);
