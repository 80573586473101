import Auth from "./auth/reducer";
import App from "./app/reducer";
import Generals from "./variables/reducer";
import Counts from "./counts/reducer";

export default {
  Auth,
  App,
  Generals,
  Counts,
};
