import React, { useEffect, useState } from "react";
import {
  faClipboardUser,
  faUserGroup,
  faUsersGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import StatisticsCard from "../uielements/StatisticsCard";
import { newPermissionValue } from "../../helpers/constants";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  @media only screen and (max-width: 1500px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media only screen and (max-width: 640px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media only screen and (max-width: 425px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const HrCounts = ({ sideBarItems, ...props }) => {
  const [isEmployee, setIsEmployee] = useState(false);
  const [isTeams, setIsTeams] = useState(false);
  const [isRoles, setIsRoles] = useState(false);

  useEffect(() => {
    if (sideBarItems && sideBarItems.length > 0) {
      sideBarItems.forEach((item) => {
        if (item.moduleName === "employees") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsEmployee(true);
          } else {
            setIsEmployee(false);
          }
        }
        if (item.moduleName === "teams") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsTeams(true);
          } else {
            setIsTeams(false);
          }
        }
        if (item.moduleName === "roles") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsRoles(true);
          } else {
            setIsRoles(false);
          }
        }
      });
    }
  }, [sideBarItems]);
  return (
    <GridContainer className="width-100">
      {isEmployee && (
        <StatisticsCard
          title={"Employees and Workers"}
          icon={
            <FontAwesomeIcon
              icon={faClipboardUser}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#9D6AB0"}
          showOnlyTitle
          onClick={() => props.history.push("/employees")}
        />
      )}
      {isTeams && (
        <StatisticsCard
          title={"Teams"}
          icon={
            <FontAwesomeIcon
              icon={faUserGroup}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#8BBC27"}
          showOnlyTitle
          onClick={() => props.history.push("/teams")}
        />
      )}
      {isRoles && (
        <StatisticsCard
          title={"Roles & Permissions"}
          icon={
            <FontAwesomeIcon
              icon={faUsersGear}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#20CCE2"}
          showOnlyTitle
          onClick={() => props.history.push("/roles")}
        />
      )}
    </GridContainer>
  );
};

export default withRouter(
  connect((state) => ({ sideBarItems: state.Auth.sideBarItems }))(HrCounts)
);
