import React, { Component } from "react";

import { Layout, Button } from "antd";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../components/utility/logo";
import Menu from "../../components/uielements/menu";
import Scrollbars from "../../components/utility/customScrollBar.js";
import SidebarWrapper from "./sidebar.style";
import { superadminDashboard } from "./options";
import appActions from "../../redux/app/actions";
import { connect } from "react-redux";
import { getLogo, getMenuItemData } from "../../helpers/utility";
import { themeConfig } from "../../settings";
import themes from "../../settings/themes";
import { loginUserType, moduleIds } from "../../helpers/constants.js";

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } =
  appActions;

const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
    const sideBarItems = this.props.sideBarItems.filter(
      (module) => !moduleIds.includes(module.moduleId)
    );
    this.state = {
      sideArray:
        this.props.role === "superadmin" ? superadminDashboard : sideBarItems,
    };
    // this is constructor so we initialize state value
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.role !== nextProps.role) {
      const sideBarItems = nextProps.sideBarItems.filter(
        (module) => !moduleIds.includes(module.moduleId)
      );
      this.setState({
        sideArray:
          nextProps.role === "superadmin" ? superadminDashboard : sideBarItems,
      });
    }
  }

  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }

  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const {
      // moduleId,
      moduleDescription,
      moduleName,
      children,
    } = singleOption;
    let iconName = getLogo(moduleName);
    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={moduleName}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <i className={iconName} />
              <span className="nav-text">{moduleDescription}</span>
            </span>
          }
        >
          {children.map((child) => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  {moduleDescription}
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={moduleName}>
        <Link to={`${url}/${moduleName}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={iconName} />
            <span className="nav-text">{moduleDescription}</span>
          </span>
        </Link>
      </Menu.Item>
    );
  };
  render() {
    const {
      app,
      toggleOpenDrawer,
      height,
      collapsed,
      collapsedWidth,
      siderStyle,
      showCollapseBtn,
      handleSidebarCollapsedBtnClick,
    } = this.props;
    const { openDrawer } = app;
    const onMouseEnter = (event) => {
      // if (openDrawer === false) {
      //   toggleOpenDrawer();
      // }
      // return;
    };
    const onMouseLeave = () => {
      // if (openDrawer === true) {
      //   toggleOpenDrawer();
      // }
      // return;
    };
    const customizedTheme = themes[themeConfig.theme];
    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
      height: "100vh",
    };
    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: customizedTheme.textColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };
    // const submenuColorSetting = {
    // 	color: customizedTheme.textColor
    // 	 display : "flex",
    // 	 alignItems : "center"
    // };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={250}
          collapsedWidth={collapsedWidth}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={{ ...styling, ...siderStyle }}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 120 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={"inline"}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {/* <Menu.Item key={"Messages"}>
                <Link to={`/messages`}>
                  <span className="isoMenuHolder" style={submenuColor}>
                    <i className={"fas fa-comment-alt"} />
                    <span className="nav-text">{"Messages"}</span>
                  </span>
                </Link>
              </Menu.Item> */}
              {/* {this.state.sideArray.length > 0 &&
                this.state.sideArray.map(
                  (singleOption) =>
                    singleOption.permissionId !== 2 &&
                    // singleOption.moduleId !== 16 &&
                    this.getMenuItem({
                      submenuStyle,
                      submenuColor,
                      singleOption,
                    })
                )} */}

              {this.props.authType !== loginUserType.superadmin ?
                (
                  this.props.menuSystemAccessibleModuleList && this.props.menuSystemAccessibleModuleList.length > 0 &&
                  this.props.menuSystemAccessibleModuleList.map((item, index) => {
                    const menuItemData = getMenuItemData(item.description, this.props.sideBarItems);
                    return (
                      <Menu.Item key={menuItemData.route}>
                        <Link to={menuItemData.route}>
                          <span className="isoMenuHolder" style={submenuColor}>
                            <i className={menuItemData.icon} />
                            <span className="nav-text">{menuItemData.title}</span>
                          </span>
                        </Link>
                      </Menu.Item>
                    );
                  })
                ) : (
                  superadminDashboard && superadminDashboard.length > 0 &&
                  superadminDashboard.map((item, index) => {
                    const menuItemData = getMenuItemData(item.moduleDescription, this.props.sideBarItems);
                    return (
                      <Menu.Item key={menuItemData.route}>
                        <Link to={menuItemData.route}>
                          <span className="isoMenuHolder" style={submenuColor}>
                            <i className={menuItemData.icon} />
                            <span className="nav-text">{menuItemData.title}</span>
                          </span>
                        </Link>
                      </Menu.Item>
                    );
                  })
                )}
            </Menu>
          </Scrollbars>
          {showCollapseBtn && (
            <div className="collapseSidebarBtn">
              <Button
                type="default"
                icon="double-left"
                size={"large"}
                onClick={handleSidebarCollapsedBtnClick}
              />
            </div>
          )}
          {/* <div
						className="isoDashboardMenu"
						style={{ padding: '0px' }}>
						<Menu theme="dark">
							<Menu.Item>
								<Link to={`${this.props.url}settings`}>
									<span
										className="isoMenuHolder"
										style={submenuColorSetting}>
										<i className="fas fa-tools" />
										<span className="nav-text">
											{'Settings'}
										</span>
									</span>
								</Link>
							</Menu.Item>
						</Menu>
					</div> */}
        </Sider>
      </SidebarWrapper >
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.App.toJS(),
  height: state.App.toJS().height,
  sideBarItems: state.Auth.sideBarItems,
  role: state.Auth.type,
  authType: state.Auth.type,
  menuSystemAccessibleModuleList: state.Auth.menuSystemAccessibleModuleList,
});

export default withRouter(
  connect(mapStateToProps, {
    toggleOpenDrawer,
    changeOpenKeys,
    changeCurrent,
    toggleCollapsed,
  })(Sidebar)
);
