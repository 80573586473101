import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  faIndustry,
  faPeopleCarry,
  faTruckRampBox,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import StatisticsCard from "../uielements/StatisticsCard";
import Api from "../../helpers/apiHandler";
import countsActions from "../../redux/counts/actions";
import { newPermissionValue } from "../../helpers/constants";

const { updateCount } = countsActions;
const api = new Api();

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  @media only screen and (max-width: 1500px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media only screen and (max-width: 640px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media only screen and (max-width: 425px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const SuppliersCount = ({
  onUpdateCount,
  counts,
  companyId,
  sideBarItems,
  ...props
}) => {
  const [count, setCount] = useState({});
  const [isManufacturers, setIsManufacturers] = useState(false);
  const [isSuppliers, setIsSuppliers] = useState(false);
  const [isPurchaseOrders, setIsPurchaseOrders] = useState(false);

  useEffect(() => {
    if (sideBarItems && sideBarItems.length > 0) {
      sideBarItems.forEach((item) => {
        if (item.moduleName === "manufacturer") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsManufacturers(true);
          } else {
            setIsManufacturers(false);
          }
        }
        if (item.moduleName === "supplier") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsSuppliers(true);
          } else {
            setIsSuppliers(false);
          }
        }
        if (item.moduleName === "orders") {
          if (item.permissionId != newPermissionValue.noAccess) {
            setIsPurchaseOrders(true);
          } else {
            setIsPurchaseOrders(false);
          }
        }
      });
    }
  }, [sideBarItems]);

  useEffect(() => {
    if (companyId) {
      getCount();
    }
  }, [companyId]);

  useEffect(() => {
    if (counts) {
      setCount(counts);
    }
  }, [counts]);

  const getCount = () => {
    const payload = {
      companyId: companyId,
      menuType: "SUPPLIERS",
    };
    api
      .post("common/count", {
        isMultipart: false,
        data: payload,
      })
      .then((response) => {
        if (response.status === 1) {
          onUpdateCount({
            supplier: {
              manufacturers: response.data.manufacturer,
              suppliers: response.data.supplier,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  return (
    <GridContainer className="width-100">
      {isManufacturers && (
        <StatisticsCard
          title={"Manufacturers"}
          count={count.manufacturers || 0}
          icon={
            <FontAwesomeIcon
              icon={faIndustry}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#9D6AB0"}
          onClick={() => props.history.push("/manufacturer")}
        />
      )}
      {isSuppliers && (
        <StatisticsCard
          title={"Suppliers"}
          count={count.suppliers || 0}
          icon={
            <FontAwesomeIcon
              icon={faPeopleCarry}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#8BBC27"}
          onClick={() => props.history.push("/supplier")}
        />
      )}
      {isPurchaseOrders && (
        <StatisticsCard
          title={"Purchase Orders Out"}
          icon={
            <FontAwesomeIcon
              icon={faTruckRampBox}
              style={{
                height: "16px",
                width: "16px",
                color: "#fff",
              }}
            />
          }
          color={"#20CCE2"}
          showOnlyTitle
          onClick={() => props.history.push("/purchase-orders")}
        />
      )}
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.Auth.company.id,
  counts: state.Counts.supplier,
  sideBarItems: state.Auth.sideBarItems,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateCount: (data) => dispatch(updateCount(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SuppliersCount)
);
