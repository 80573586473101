import styled from 'styled-components'
import poster from '../../image/sign.jpg'

export const StyledPayment = styled.div`
  background-image: url(${poster});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .content-container {
    background-color: #fff;
    box-shadow: 0 0 10px -1px;
    border-radius: 5px;
    width: 70vh;
  }
  .error-msg {
    color: red;
    font-size: 16px;
  }
`
