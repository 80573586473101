import actions from "./actions";

const initState = {
  home: {
    activeTasks: 0,
    unreadMessages: 0,
    unreadNotifications: 0,
    activeProductionRuns: 0,
  },
  taskAndMessaging: {
    currentTasks: 0,
    upcomingTasks: 0,
    unreadMessages: 0,
    unreadNotifications: 0,
  },
  regulatoryAndCompliance: {
    numberOfVisitorsLog: 0,
  },
  production: {
    recipes: 0,
    ingredients: 0,
    currentProjects: 0,
    upcomingProjects: 0,
  },
  customers: {
    pendingOrders: 0,
    overdueOrders: 0,
    customersOverview: {
      today: 0,
      week: 0,
      month: 0,
      quarter: 0,
      year: 0,
      total: 0,
    },
  },
  supplier: {
    manufacturers: 0,
    suppliers: 0,
  },
};

export default function countsReducer(state = initState, action) {
  switch (action.type) {
    case actions.UPDATE_COUNT:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
}
