import React from "react";
import { Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { StylesWrapper } from "./index.style";

const { Option } = Select;

const StatisticsCard = ({
  title,
  count = "0",
  icon,
  color,
  showDropdown = false,
  showOnlyTitle,
  onClick,
  dropDownChange,
  option,
}) => {
  return (
    <StylesWrapper color={color} onClick={onClick}>
      <div className="display-flex align-center">
        <div className="iconBox">{icon}</div>
      </div>
      {showOnlyTitle ? (
        <span className="cardTitle2">{title}</span>
      ) : (
        <div className="flex-1 display-flex flex-column justify-center gap-8px">
          <div className="display-flex justify-space-between align-center">
            <span className="cardTitle">{title}</span>
            {showDropdown && (
              <Select
                defaultValue={option.length > 0 ? option[0].key : "1"}
                style={{ width: 108 }}
                suffixIcon={
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{
                      height: "16px",
                      width: "16px",
                      color: "#fff",
                    }}
                  />
                }
                className="customSelect"
                onChange={dropDownChange}
              >
                {option.length > 0 ? (
                  option.map((item) => (
                    <Option value={item.key}>{item.value}</Option>
                  ))
                ) : (
                  <>
                    <Option value="1">1 Week</Option>
                    <Option value="2">2 Weeks</Option>
                    <Option value="3">1 Month</Option>
                    <Option value="4">2 Months</Option>
                    <Option value="5">6 Months</Option>
                    <Option value="6">1 Year</Option>
                  </>
                )}
              </Select>
            )}
          </div>
          <span className="count">{count}</span>
        </div>
      )}
    </StylesWrapper>
  );
};

export default StatisticsCard;
