import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { routerMiddleware, routerReducer } from "react-router-redux";

import { createBrowserHistory as createHistory } from "history";
import createSagaMiddleware from "redux-saga";
import reducers from "../redux/reducers";
import rootSaga from "../redux/sagas";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Configuration for Redux Persist
const persistConfig = {
  key: "root", // key for the localStorage entry
  storage, // define which storage to use
  // Optionally, you can whitelist specific reducers to be persisted
  whitelist: ["Auth"], // only "Auth" reducer will be persisted
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    ...reducers,
    router: routerReducer,
  })
);

const store = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(...middlewares))
);

// Create the persisted store
const persistor = persistStore(store);

store.subscribe(() => {
  localStorage.setItem(
    "isLoggedIn",
    store.getState().Auth ? store.getState().Auth.isLoggedIn : false
  );
  localStorage.setItem(
    "accessToken",
    store.getState().Auth ? store.getState().Auth.accessToken : null
  );
  localStorage.setItem(
    "anyPlanActivated",
    store.getState().Auth ? store.getState().Auth.anyPlanActivated : false
  );
});

sagaMiddleware.run(rootSaga);
export { store, persistor, history };
