import actions from './actions'
import { getXAPIKey } from '../../helpers/utility'

const initState = {
  idToken: null,
  id: 0,
  type: '',
  role: '',
  firstName: '',
  lastName: '',
  email: '',
  profileImage: '',
  accountStatus: '',
  pendingPayment: false,
  hasCard: false,
  accessToken: localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : getXAPIKey(),
  isLoggedIn: localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') === 'true' : false,
  company: { id: 0, name: '', productionUnitCount: 0 },
  sideBarItems: [],
  anyPlanActivated: localStorage.getItem('anyPlanActivated') ? true : false,
}

export default function authReducer (state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      // return state.set('idToken', action.token);
      return {
        ...state,
        idToken: action.token,
        isLoggedIn: true
      }
    case actions.LOGOUT:
      return initState
    case actions.UPDATE_PROFILE:
      return {
        ...state,
        ...action.data
      }
    case actions.PENDING_PAYMENT:
      return {
        ...state,
        pendingPayment: action.data.showPage,
        id: action.data.userId,
        hasCard: action.data.hasCard
      }
    default:
      return state
  }
}
